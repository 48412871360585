import fetchOld from '../js/fetchToken'

const fetch = (data, autoGetToken = false) => {
  if (!autoGetToken) {
    return fetchOld(data)
  } else {
    if (!data.data) {
      data.data = {};
    }
    if (!data.params) {
      data.params = {}
    }
    data.params.token = JSON.parse(localStorage.getItem('userInfo')).token;
    data.data.token = JSON.parse(localStorage.getItem('userInfo')).token;
    return fetchOld(data)
  }
}

// 获取app下载地址
export const getAppVersion = (params = {}) => {
	return fetch({
		url: '/uc/getAppVersion',
		params
	})
}